import React from "react"
import {Layout} from "../components/Layout/layout";
import {PageTitle} from "../components/PageTitle";
import {Container, Row} from "reactstrap";
import {graphql} from "gatsby";
import {Panel} from "../components/Panel";
import {MDXRenderer} from "gatsby-plugin-mdx";

export default (props) => (
    <React.Fragment>
        <Layout pageName="biographies">
            <PageTitle title="Biographies"/>
            <Container>
                <Row className="margin-bottom-20">
                    {props.data.biographies.edges.map((o, idx) => (
                        <Panel key={idx} title={o.node.frontmatter.title} subtitle={o.node.frontmatter.subtitle}
                               image={o.node.frontmatter.image} width={o.node.frontmatter.width}>
                            <MDXRenderer>{o.node.body}</MDXRenderer>
                        </Panel>
                    ))}
                </Row>
            </Container>
        </Layout>
    </React.Fragment>
)

export const query = graphql`
    query {
        biographies: allMdx(
            sort: {
                fields: [frontmatter___sort]
            }, 
            filter: {
                fileAbsolutePath: {
                    regex: "/biographies/"
                }, 
                frontmatter: {
                    enabled: { 
                        eq: true
                    }
                }
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        subtitle
                        image
                        enabled
                        sort
                        width
                    }
                    body
                }
            }
        }
    }
`;
